import styled from "styled-components";

export const Content = styled.div`
    margin-top: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;

    .slick-arrow {
        width: 2rem;
        height: 2rem;

        :before {
            content: none;
        }
    }

    .slick-next {
        padding-left: 4px;
        padding-top: 8px;
    }

    .slick-prev {
        padding-left: -2px;
        padding-top: 8px;
    }
    .slick-slider {
        div {
            margin: 0 auto;

            @media (max-width: 768px) {
                margin: 0 auto;
            }
        }
    }

    .market-title {
        width: 100%;
        margin: 2rem 0;

        p {
            font-size: 2.375rem;

            strong {
                color: #fe8418;
                font-weight: bold;
            }

            &:before {
                content: "";
                width: 39%;
                height: 2px;
                background: #000;
                position: absolute;
                top: 7%;
                right: 0%;

                @media (max-width: 768px) {
                    content: none;
                }
            }

            @media (max-width: 1024px) {
                font-size: 2rem;
                margin-left: 1rem;

                &:before {
                    width: 34%;
                    top: 4%;
                    right: 5%;
                }
            }

            @media (max-width: 768px) {
                font-size: 2rem;
                text-align: center;
            }
        }
    }

    .segments {
        width: 100%;
        margin: 1rem 0;

        .slick-slider {
            div {
                margin: 0.5rem auto;

                @media (max-width: 768px) {
                    margin: 0 auto;
                }
            }
        }

        @media (max-width: 768px) {
            max-width: 700px;

            text-align: center;
        }

        @media (max-width: 414px) {
            max-width: 300px;
        }
    }

    .product-offer {
        text-align: right;
        margin: 1rem;
        width: 100%;
        position: relative;

        p {
            font-size: 2.375rem;
            strong {
                color: #fe8418;
                font-weight: bold;
            }

            &:before {
                content: "";
                width: 57%;
                height: 2px;
                background: #fe8418;
                position: absolute;
                top: 58%;
                left: 0%;

                @media (max-width: 1024px) {
                    width: 45%;
                    left: 7%;
                }

                @media (max-width: 768px) {
                    content: none;
                }
            }

            @media (max-width: 768px) {
                margin-top: 2rem;
                font-size: 2rem;
                text-align: center;
            }
        }
    }

    .product-wrap {
        text-align: center;
        width: 70%;

        .slick-slide {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .slick-arrow {
            width: 2rem;
            height: 2rem;

            :before {
                content: none;
            }
        }

        .slick-next {
            padding-left: 6px;
            padding-top: 8px;
        }

        .slick-prev {
            padding-left: 4px;
            padding-top: 8px;
        }

        a {
            padding: 0.8rem 8rem;
            background: #fe8418;
            font-size: 1.125rem;
            font-weight: bold;
            text-transform: uppercase;
            text-decoration: none;
            color: #fff;
            border-radius: 2rem;
            margin-top: 2rem;
            transition: all 0.2s;

            &:hover {
                filter: brightness(0.8);
            }
        }

        @media (max-width: 768px) {
            max-width: 600px;

            a {
                padding: 0.8rem 6rem;
            }
        }

        @media (max-width: 414px) {
            .slick-list {
                max-width: 300px;
            }
            .slick-next {
                top: 35%;
                right: -15%;
            }

            .slick-prev {
                top: 35%;
                left: -15%;
            }

            a {
                padding: 0.8rem 4rem;
                font-size: 0.52rem;
                margin: 0;
            }
        }

        @media (max-width: 360px) {
            .slick-list {
                max-width: 300px;
            }

            a {
                font-size: 0.8rem;
            }
        }
    }

    .product {
        text-align: center;

        strong {
            font-size: 1.75rem;
            font-weight: bold;
        }

        > div {
            border: 1px solid #fe8418;
            border-radius: 50%;
            width: 10rem;
            height: 10rem;

            display: flex;
            align-items: center;
            justify-content: center;

            div {
                border-radius: 50%;
                background-color: #fe8418;
                display: flex;
                align-items: center;
                justify-content: center;

                width: 9rem;
                height: 9rem;

                img {
                    width: 5.375rem;
                }
            }
        }

        @media (max-width: 768px) {
            strong {
                font-size: 1.25rem;
            }
            > div {
                width: 8rem;
                height: 8rem;

                div {
                    width: 7rem;
                    height: 7rem;

                    img {
                        width: 4.375rem;
                    }
                }
            }
        }
    }
`;

export const SegmentsContainer = styled.div`
    width: 200px;
    height: 200px;
    margin: 1rem;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    strong {
        position: absolute;
        text-transform: uppercase;
        color: #fff;
        bottom: 1.2rem;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    }

    @media (max-width: 1024px) {
        width: 180px;
        height: 180px;
        margin: 0.8rem;
    }

    @media (max-width: 768px) {
        width: 180px;
        height: 180px;
        margin: 0.8rem;
    }

    @media (max-width: 414px) {
        width: 170px;
        height: 170px;
        margin: 0;
    }
`;

export const Background = styled.div<{ image }>`
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 65.52%, rgba(0, 0, 0, 1) 100%),
        url(${(props) => props.image});
    background-size: cover;
    border-radius: 30px;
    cursor: pointer;

    &:hover {
        background-image: linear-gradient(180deg, rgba(254, 132, 24, 0) 64.48%, #fe8418 100%),
            url(${(props) => props.image});
    }
`;
