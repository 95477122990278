import React from 'react';
import Slider from 'react-slick'
import {Container, Row, Col} from 'react-bootstrap'
import { Content } from './styles';
import { NextArrowCarusel, PrevArrowCarusel, CardPie, DifferencesCard } from './HighlightsComponents'


const Highlights = () => {


    const setting = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <NextArrowCarusel />,
        prevArrow: <PrevArrowCarusel />,

        responsive: [
            {
              breakpoint: 416,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
        ],

      }

      const differencesSetting = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 2,
        rows: 2,
        autoplay: false,
        autoplaySpeed: 2000,
        centerPadding: "60px",
        nextArrow: <NextArrowCarusel />,
        prevArrow: <PrevArrowCarusel />,

        responsive: [
            {
              breakpoint: 770,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                rows: 1,
                arrows: true,
              }
            },
            {
                breakpoint: 414,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  rows: 1,
                }
              }
        ],

      }

  return (
      <Container>
          <Content>
                <div className="title" id="destaques">
                    <p style={{ lineHeight: "2rem" }}>
                        Por que
                        <strong> fotos profissionais </strong>
                        são
                        <strong> importantes </strong>
                        para que sua empresa se
                        <strong> destaque?</strong>
                    </p>
                </div>

                <div className="pie-charts">
                    <Slider {...setting}>
                        <CardPie
                            image="/images/pie-45.png"
                            number={45}
                            textUp="Fotos profissionais aumentam, em média,"
                            textDown=" os cliques de sua publicação."
                        />

                        <CardPie
                            isBrSpace
                            image="/images/pie-60.png"
                            number={60}
                            textUp="Consumidores clicam"
                            textDown="mais em resultados de busca com imagem."
                        />

                        <CardPie
                            image="/images/pie-94.png"
                            number={94}
                            textUp="Artigos com imagem atraem"
                            textDown="mais visualizações."
                        />

                    </Slider>
                </div>

                <div className="info-card">
                    <div className="info-card-1">
                        <img src="/images/home/info-card-1.jpg" alt="imagem" />
                        <div>
                            <header>
                                <p> <strong>Aumento de</strong> conversões:</p>
                            </header>
                            <main>
                                <p>
                                Para 67% dos consumidores as fotos são fundamentais para realizar a compra.
                                </p>

                            </main>
                        </div>
                    </div>
                    <div className="info-card-2">
                        <div>
                            <header>
                                <p> <strong>Fotos atraem</strong> os clientes:</p>
                            </header>
                            <main>
                                <p>
                                    63% dos consumidores consideram a qualidade das imagens mais importante que as informações do anúncio.
                                </p>
                            </main>
                        </div>
                        <img src="/images/home/info-card-2.jpg" alt="imagem" />
                    </div>

                    <div className="info-card-3">
                        <img src="/images/home/info-card-2.jpg" alt="imagem" />
                        <div>
                            <header>
                                <p> <strong>Fotos atraem</strong> os clientes:</p>
                            </header>
                            <main>
                                <p>
                                    63% dos consumidores consideram a qualidade das imagens mais importante que as informações do anúncio.
                                </p>
                            </main>
                        </div>
                    </div>
                </div>


                <div className="differences">
                        <p> <strong>Diferenciais</strong> competitivos</p>

                    <div className="wrap-carousel">
                        <Slider {...differencesSetting}>
                            <DifferencesCard
                                image="/images/home/camera.png"
                                title="Fácil de usar"
                            >
                                <p>
                                Nem sempre é fácil conseguir fotos profissionais. Nós te conectamos aos melhores fotógrafos do Brasi.
                                </p>
                            </DifferencesCard>


                            <DifferencesCard
                                image="/images/home/user.png"
                                title="Fotógrafos especializados"
                            >
                                <p>
                                Contamos com parceiros especializados em cada segmento que atuamos, garantindo a melhor entrega para você e sua empresa.
                                </p>
                            </DifferencesCard>

                            <DifferencesCard
                                image="/images/home/dolar.png"
                                title="Venda mais"
                            >
                                <p>
                                Uma foto profissional valoriza seu imóvel, destaca seu produto e eterniza suas lembranças.
                                </p>
                            </DifferencesCard>
                            <DifferencesCard
                                image="/images/home/shild.png"
                                title="Rápido e confiável"
                            >
                                <p>
                                Tenha acesso a fotos profissionais de maneira segura, simples, prática e descomplicada.
                                </p>
                            </DifferencesCard>


                        </Slider>
                    </div>
                </div>
          </Content>
      </Container>
  )
}

export default Highlights;



