import React from "react";

import { SegmentsContainer, Background } from "./styles";

const Segments = ({ image, text, link = "" }) => {
    if (link) {
        return (
            <a href={link}>
                <SegmentsContainer>
                    <Background image={image} />
                    <strong>{text}</strong>
                </SegmentsContainer>
            </a>
        );
    }

    return (
        <SegmentsContainer>
            <Background image={image} />
            <strong>{text}</strong>
        </SegmentsContainer>
    );
};

export default Segments;
