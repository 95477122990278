import React, {Dispatch, SetStateAction} from 'react';
import { Sidebar, NavMenu, NavLink, LinkButton } from './styles';


type linkProps ={
    id:string,
    link:string,
    target:string,
    label: string
}

export type Navbarprops = {
links?: linkProps[]
button?: {
    hasButton:boolean,
    link: string,
    label:string
}
isOpen?:boolean
setIsOpen: Dispatch<SetStateAction<boolean>>
}

type Props = {
  isOpen: boolean;
}


const SideBar = ({isOpen, links, button}:Navbarprops) => {

  return (
    <>
      {isOpen ? (
            <Sidebar isOpen={isOpen}>

                { links ? (
                        <NavMenu>
                        { links.map(link => (
                            <NavLink key={link.id} href={link.link} target={link.target}>
                                {link.label}
                            </NavLink>
                        ))}

                        {
                            !button ? ("") : (
                                button.hasButton && (
                                    <LinkButton href={button.link}>
                                        {button.label}
                                    </LinkButton>
                                )
                            )
                        }

                    </NavMenu>

                    ) : (
                        <NavMenu>
                            <NavLink href="https://fotop.io" target="_blank">Sou Fotógrafo</NavLink>
                            <NavLink href="/business" >Business</NavLink>
                            <NavLink href="https://ajuda.fotop.com.br/kb/pt-br/form/1812" target="_blank">Contato</NavLink>
                            <NavLink href="/login">Login</NavLink>
                        </NavMenu>
                )}
              {/* <NavMenu>
                <NavLink href="https://fotop.io" target="_blank">Sou Fotógrafo</NavLink>
                <NavLink href="http://business.fotografoja.com/" target="_blank">Business</NavLink>
                <NavLink href="https://ajuda.fotop.com.br/kb/pt-br/form/1812" target="_blank">Contato</NavLink>
                <NavLink href="/login">Login</NavLink>
              </NavMenu> */}
          </Sidebar>
      ) : ""}
    </>
  )
}

export default SideBar;


