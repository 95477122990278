import React from 'react';
import { Container } from 'react-bootstrap';
import { Background, Content,KindServiceCard , KindServiceContainer, KindServiceMobile} from './styles'


const KindService = () => {
  return (
      <Background>
          <Container>
              <Content>
                  <div className="title">
                    <p>
                        Encontre a
                            <strong> solução ideal </strong>
                        para o
                            <strong> seu negócio </strong>
                    </p>

                  </div>
                  <p>
                        Profissionais qualificados e uma equipe especializada sempre à disposição para te entregar as melhores imagens.
                  </p>

                  <KindServiceContainer>

                      <KindServiceCard orientation="left" >
                          <img src="/images/home/info-card-3.jpg" alt="imagem" />
                          <div>
                              <header>FOTOP <strong>SERVIÇOS DIRETO</strong></header>
                              <p>
                              Indicamos o profissional certo para atender a sua necessidade ou da sua empresa.
                            <br />
                            <br />
                              Ideal para clientes que precisam de sessões de fotos pontuais e queiram tratar diretamente com o fotógrafo.

                              </p>
                            <br />
                            <a href="/direto"> Saiba mais</a>
                          </div>
                      </KindServiceCard>

                      <KindServiceCard orientation="right" >
                          <div>
                              <header>FOTOP <strong>SERVIÇOS BUSINESS</strong></header>
                              <p>
                              Gerenciamos toda a sua operação de fotos.
                              <br/>
                              <br/>
                              Ideal para empresas que possuem um volume recorrente de sessões de fotos, como imobiliárias, plataformas de restaurante, veículos e e-commerce.

                              </p>
                            <br />
                            <a href="/business"> Saiba mais</a>
                          </div>
                          <img src="/images/home/info-card-4.jpg" alt="imagem" />
                      </KindServiceCard>

                      <KindServiceMobile  >
                          <div>
                              <img src="/images/home/info-card-3.jpg" alt="imagem" />
                              <header>FOTOP <strong>SERVIÇOS DIRETO</strong></header>
                              <p>
                                Indicamos o profissional certo para atender a sua necessidade ou da sua empresa.
                                necessidade ou da sua empresa.
                                <br />
                                <br />
                                Ideal para clientes que precisam de sessões de fotos pontuais e queiram tratar diretamente com o fotógrafo.
                              </p>
                            <br />
                            <a href="/direto"> Saiba mais</a>
                          </div>
                      </KindServiceMobile>

                      <KindServiceMobile >
                          <div>
                              <img src="/images/home/info-card-4.jpg" alt="imagem" />
                              <header>FOTOP <strong>SERVIÇOS BUSINESS</strong></header>
                              <p>
                                Indicamos o profissional certo para atender a sua necessidade ou da sua empresa.
                                <br />
                                <br />
                                Ideal para clientes que precisam de sessões de fotos pontuais e queiram tratar diretamente com o fotógrafo.
                              </p>
                            <br />
                            <a href="/business"> Saiba mais</a>
                          </div>

                      </KindServiceMobile>

                  </KindServiceContainer>

              </Content>
          </Container>
      </Background>
  )
}

export default KindService;
