import styled from "styled-components";

export const Background = styled.div`
    background: linear-gradient(81.56deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.72) 99.99%, rgba(0, 0, 0, 0.52) 100%);
`;

export const Content = styled.div`
    padding: 2rem 0;
    position: relative;

    > p {
        margin: 0;
    }

    .title {
        p {
            max-width: 1080px;
            color: #fff;
            font-size: 2.325rem;
            text-align: left;
            margin-left: 3rem;

            strong {
                color: #fe8418;
                font-weight: bold;
            }

            &:before {
                content: "";
                width: 27%;
                height: 2px;
                background: #fe8418;
                position: absolute;
                top: 9%;
                right: 0%;
            }
        }

        @media (max-width: 1024px) {
            p {
                font-size: 2.2rem;

                &:before {
                    width: 14%;
                    top: 10%;
                    right: 6%;
                }
            }
        }

        @media (max-width: 768px) {
            p {
                max-width: 680px;
                font-size: 2.8rem;

                &:before {
                    width: 50%;
                    top: 17.2%;
                    right: 6%;
                }
            }
        }

        @media (max-width: 414px) {
            p {
                font-size: 1.7rem;
                margin-left: 1.3rem;
                margin-right: 0.6rem;

                &:before {
                    content: none;
                }
            }
        }
    }

    p {
        max-width: 530px;
        font-size: 1.1rem;
        color: #fff;
        margin-left: 3rem;
        padding-top: 0.5rem;

        @media (max-width: 414px) {
            margin-left: 1.3rem;
            margin-right: 0.6rem;
        }
    }
`;

export const KindServiceContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-top: 1.5rem;
`;

export const KindServiceMobile = styled.div`
    display: none;

    @media (max-width: 600px) {
        display: flex;
        justify-content: center;
        position: relative;

        div {
            margin: 1rem 0;
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-radius: 1rem;

            img {
                margin: 1rem;
                border-radius: 1rem;

                width: 20rem;
            }

            header {
                font-size: 1.8rem;
                padding-bottom: 1rem;
                margin: 0.7rem 1.1rem;
                text-align: center;

                strong {
                    color: #fe8418;
                }
            }

            p {
                color: #2e2e2e;
                margin: 0 1rem;
                font-size: 1rem;
            }

            a {
                margin: 1.4rem 1rem;
                padding: 0.6rem 2rem;
                background: #fe8418;
                text-decoration: none;
                color: #fff;
                border-radius: 1.3rem;
                transition: all 0.2s;

                &:hover {
                    filter: brightness(0.8);
                }
            }
        }
    }
`;

export const KindServiceCard = styled.div<{ orientation: "right" | "left" }>`
    display: flex;
    align-items: center;
    margin: 1rem;
    margin-${(props) => props.orientation}: 8rem;

    img {
        width: 27rem;
        height: 20rem;
        border-top-${(props) => props.orientation}-radius: 1.8rem;
        border-bottom-${(props) => props.orientation}-radius: 1.8rem;

    }

    div {
        width: 35rem;
        height: 20rem;
        background: #fff;
        text-align: ${(props) => props.orientation};
        border-top-${(props) => (props.orientation === "right" ? "left" : "right")}-radius: 1.8rem;
        border-bottom-${(props) => (props.orientation === "right" ? "left" : "right")}-radius: 1.8rem;

        header {
            font-size: 1.8rem;
            padding-bottom: 0.5rem;
            margin: .7rem 1.1rem;
            border-bottom: 2px solid #000;

            strong {
                color: #fe8418;
            }
        }


        p {
            color: #2E2E2E;
            margin: 0 1rem;
        }

        a {
            margin:1.4rem 1rem;
            padding: 0.6rem 2rem;
            background: #fe8418;
            text-decoration: none;
            color: #fff;
            border-radius: 1.3rem;
            transition: all .2s;

            &:hover {
                filter: brightness(.8)
            }
        }



    }

    @media (max-width: 1024px) {

        margin-${(props) => props.orientation}: 6rem;
        img {
            width: 24rem;
            height: 18rem;
        }

        div {
            width: 30rem;
            height: 18rem;
        }

        p {

            font-size: 1rem;
        }



    }

    @media (max-width: 768px) {

        margin-${(props) => props.orientation}: 3.5rem;
        img {
            width: 20rem;
            height: 16rem;
        }

        div {
            width: 22rem;
            height: 16rem;


            header {
            font-size: 1.2rem;

            }


            p {
               font-size: 0.8rem;
            }

            a {
                padding: 0.4rem 1.6rem;
                background: #fe8418;
                font-size: 0.8rem;
            }
    }

    @media (max-width: 600px) {
        display: none;
    }
`;
