import React from "react";
import Banner from "./Banner";
import Highlights from "./Highlights";
import KindService from "./KindService";
import MarketOperate from "./MarketOperate";
import Footer from "~/components/Footer";
import DepoCarousel from "~/components/DepoCarousel";
import BelowBanner from "./Banner/BelowBanner";

import "bootstrap/dist/css/bootstrap.min.css";

const HomePage = () => {
  return (
      <>
        <Banner />

        <Highlights />
        <KindService />
        <MarketOperate />
        <BelowBanner />
        <DepoCarousel />
        <Footer />
      </>
  )
}

export default HomePage;
