import React from 'react';
import styled from "styled-components";
import {Container} from 'react-bootstrap'

export const Background = styled.div`
    background: linear-gradient(81.69deg, #000000 0%, rgba(0, 0, 0, 0) 119.08%), url("/images/home/bellow-banner.png") no-repeat;
    background-size: cover;
    margin: 3rem 0;
    height: 24rem;

    @media (max-width: 768px) {
        background-position: center;
    }

`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;



    .title-side-banner{
        p {
            padding: 2rem 0 1rem 0;
            font-size: 3rem;
            color: #fff;

            strong {
                color: #fe8418;
            }
        }
    }

    > p {
        max-width: 760px;
        color: #fff;
        font-size: 1.6rem;
        text-align: center;

        margin: 1rem 0;
    }

    a {

    margin: 1.4rem 1rem;
    padding: 0.6rem 2rem;
    background: #fe8418;
    text-decoration: none;
    color: #fff;
    border-radius: 1.3rem;
    transition: all .2s;
    text-transform: uppercase;
    font-weight: bold;

        &:hover{
            filter: brightness(0.8)
        }
    }

    @media (max-width: 414px) {
        text-align: center;

        .title-side-banner{
            p {
                font-size: 2.3rem;
            }
        }

        > p {
        max-width: 375px;
        font-size: 1.2rem;
        margin: 1rem 0;
    }
    }
`

const BelowBanner = () => {
  return (
      <Background>
          <Container>
              <Content>
                  <div className="title-side-banner">
                        <p>Você é <strong>Fotógrafo? </strong></p>
                  </div>

                  <p>
                     <strong>Viva de seus cliques</strong> e faça parte da < br/> transformação do mercado fotográfico com o <strong>Fotop!</strong>
                  </p>
                  <a target="_blank" href="https://fotop.io/">Saiba mais</a>
              </Content>
          </Container>
      </Background>
  )
}

export default BelowBanner;

