import styled from "styled-components";

export const BgWrap = styled.div`
    /* background: rgba(0, 0, 0, 0.7); */
`;

export const Nav = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 77px;
    z-index: 10;
    margin: 0;

    @media screen and (max-width: 768px) {
        justify-content: flex-start;
    }
`;

export const NavLink = styled.a`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 2rem;
    z-index: 15;

    @media screen and (max-width: 900px) {
        text-align: center;
    }

    &:hover {
        color: #fe8418;
    }
`;

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;

    button {
        background: #fe8418;
        color: #fff;
        margin: 0 2rem;
        padding: 0.4rem 1.4rem;
        border: none;
        font-weight: 600;
        border-radius: 0.9rem;

        transition: ease-in-out 0.2s filter;

        &:hover {
            filter: brightness(0.8);
        }
    }

    select {
        background: none;
        border: none;
        color: #fff;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const LinkButton = styled.a`
    padding: 0.4rem 1.5rem;
    background: #fe8418;
    border-radius: 1.3rem;
    text-decoration: none;
    color: #fff;
    transition: all 0.2s;

    &:hover {
        color: #fff;
        filter: brightness(0.8);
    }
`;

export const Sidebar = styled.nav`
    position: absolute;
    top: 0;
    right: 0;
    background: #000;
    width: 30vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 10;

    align-items: center;

    .header {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
