import React, { useState } from "react";
import Image from "next/image";
import SideBar from "../SideBar";
import { Nav, NavMenu, NavLink, BgWrap, LinkButton } from "./styles";
import ToggleMenu from "../SideBar/ToggleMenu";

type linkProps = {
    id: string;
    link: string;
    target: string;
    label: string;
};

export type Navbarprops = {
    links?: linkProps[];
    button?: {
        hasButton: boolean;
        link: string;
        label: string;
    };
    isOpen?: boolean;
};

const NavBar = ({ links, button }: Navbarprops) => {
    const [openSideBar, setOpenSideBar] = useState(false);

    const OpenSideBar = () => {
        setOpenSideBar(!openSideBar);
    };

    return (
        <BgWrap>
            <Nav>
                <SideBar links={links} button={button} isOpen={openSideBar} setIsOpen={setOpenSideBar} />
                <NavLink href="/">
                    <Image src="/images/fotop-servicos-01.svg" width={190} height={32} />
                </NavLink>
                <ToggleMenu toogle={OpenSideBar} isOpen={openSideBar} />
                {links ? (
                    <NavMenu>
                        {links.map((link) => (
                            <NavLink key={link.id} href={link.link} target={link.target}>
                                {link.label}
                            </NavLink>
                        ))}

                        {!button ? "" : button.hasButton && <LinkButton href={button.link}>{button.label}</LinkButton>}
                    </NavMenu>
                ) : (
                    <NavMenu>
                        <NavLink href="https://fotop.io" target="_blank">
                            Sou Fotógrafo
                        </NavLink>
                        <NavLink href="/business">Business</NavLink>
                        <NavLink href="https://fotop.com/fotos/r/WPSWv0" target="_blank">
                            Contato
                        </NavLink>
                        <NavLink href="/login">Login</NavLink>
                    </NavMenu>
                )}
            </Nav>
        </BgWrap>
    );
};

export default NavBar;
