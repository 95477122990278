import React from 'react';
import Image from 'next/image'
import { Container } from 'react-bootstrap'
import NavBar from '../../NavBar';

import { Background, BgNav, Content, Link } from './styles'

const Banner = () => {
    return (
        <Background>
            <BgNav>
                <NavBar />
            </BgNav>
            <Container>
                <Content>
                    <h1>
                        Já pensou em postar um job hoje e contratar o fotógrafo certo para ele amanhã?
                    </h1>
                    <p>
                        O Fotop resolve pra você em poucos cliques
                    </p>

                    <Link href="/marketplace/book">
                        SOLICITAR UM FOTÓGRAFO
                    </Link>

                    <div>
                        <a href="#lopes">
                            <Image src="/images/arrow-down.png" alt="seta para baixo" width={22} height={18} />
                        </a>
                    </div>

                </Content>
            </Container>
        </Background>
    )
}

export default Banner;
