import { motion } from "framer-motion";
import styled from "styled-components";

const Button = styled.div`
  z-index: 99;
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  right: 1rem;

  display: none;

  @media screen and (max-width:768px) {
    display: block;
  }

`;

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(28, 99%, 55%)"
    strokeLinecap="round"
    {...props}
  />
);

const ToggleMenu = ({ toogle, isOpen }) => {
  return (
    <Button onClick={toogle}>
      <svg width="25" height="25" viewBox="0 0 23 23">
        <Path
          animate={isOpen ? "open" : "closed"}
          initial={false}
          variants={{
            closed: { d: "M 2 2.5 L 20 2.5" },
            open: { d: "M 3 16.5 L 17 2.5" },
          }}
          transition={{ duration: 0.33 }}
        />
        <Path
          d="M 2 9.423 L 20 9.423"
          animate={isOpen ? "open" : "closed"}
          initial={false}
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
          transition={{ duration: 0.33 }}
        />
        <Path
          animate={isOpen ? "open" : "closed"}
          initial={false}
          variants={{
            closed: { d: "M 2 16.346 L 20 16.346" },
            open: { d: "M 3 2.5 L 17 16.346" },
          }}
          transition={{ duration: 0.33 }}
        />
      </svg>
    </Button>
  );
}


export default ToggleMenu;