import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    /* *classes do carousel */

    .slick-arrow {
        width: 2rem;
        height: 2rem;

        :before {
            content: none;
        }
    }

    .slick-next {
        padding-left: 12px;
        padding-top: 8px;
    }

    .slick-prev {
        padding-left: 12px;
        padding-top: 8px;
    }

    .slick-slider {
        margin-top: 1rem;

        div {
            margin: 0 auto;
        }
    }

    @media (max-width: 768px) {
        padding: 1rem 1rem;

        .slick-next {
            top: 25%;
            right: -7%;
        }

        .slick-prev {
            top: 25%;
            left: -7%;
        }
    }

    @media (max-width: 414px) {
        padding: 1rem 1rem;

        .slick-next {
            top: 25%;
            right: 0%;
        }

        .slick-prev {
            top: 25%;
            left: 0%;
        }
    }

    @media (max-width: 360px) {
        .slick-next {
            top: 22%;
            right: 2%;
        }

        .slick-prev {
            top: 22%;
            left: 2%;
        }
    }
    /**-------------------- */

    .title {
        p {
            max-width: 880px;
            color: #000000;
            font-size: 2.375rem;
            padding-top: 2rem;

            strong {
                color: #fe8418;
                font-weight: bold;
            }

            &:before {
                content: "";
                width: 42%;
                height: 2px;
                background: #000;
                position: absolute;
                top: 8%;
                right: 0%;
            }

            @media (max-width: 1024px) {
                max-width: 850px;
                font-size: 2.4rem;

                &:before {
                    width: 14%;
                    top: 8%;
                    right: 9%;
                }
            }

            @media (max-width: 768px) {
                font-size: 2rem;

                &:before {
                    width: 15%;
                    top: 7.2%;
                    right: 6%;
                }
            }

            @media (max-width: 414px) {
                max-width: 350px;
                font-size: 1.4rem;
                text-align: center;

                &:before {
                    content: none;
                }
            }

            @media (max-width: 414px) {
                font-size: 1.2rem;
            }
        }
    }

    .pie-charts {
        width: 100%;
    }

    .info-card {
        margin-top: 4rem;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .info-card-1 {
        display: flex;
        justify-content: flex-start;
        position: relative;
        margin-left: -8rem;

        img {
            width: 19.375rem;
            height: 14.5rem;
            border-radius: 30px;
        }

        div {
            margin: 0 0 0 1rem;

            header {
                p {
                    font-size: 1.75rem;
                    color: #2e2e2e;

                    strong {
                        color: #fe8418;
                        font-weight: bold;
                    }

                    &:before {
                        content: "";
                        width: 24%;
                        height: 2px;
                        background: #fe8418;
                        position: absolute;
                        top: 14%;
                        right: -14%;
                    }

                    @media (max-width: 768px) {
                        margin: 0;
                        &:before {
                            content: none;
                        }
                    }
                }
            }

            main {
                p {
                    max-width: 420px;
                    font-size: 1.375rem;
                }
            }
        }

        @media (max-width: 768px) {
            text-align: right;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            text-align: center;
            margin-left: 0;

            p {
                max-width: 500px;
            }
        }

        @media (max-width: 414px) {
            margin-left: 0;
            p {
            }
        }
    }

    .info-card-2 {
        text-align: right;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 1.5rem;
        position: relative;
        margin-right: -8rem;

        img {
            width: 19.375rem;
            height: 14.5rem;
            border-radius: 30px;
        }

        div {
            margin: 0 1rem 0 0;

            header {
                p {
                    font-size: 1.75rem;
                    color: #2e2e2e;

                    strong {
                        color: #fe8418;
                        font-weight: bold;
                    }

                    &:before {
                        content: "";
                        width: 24%;
                        height: 2px;
                        background: #2e2e2e;
                        position: absolute;
                        top: 20%;
                        left: -12%;
                    }

                    @media (max-width: 768px) {
                        &:before {
                            width: 12%;
                            left: 4%;
                        }
                    }
                }
            }

            main {
                p {
                    max-width: 420px;
                    font-size: 1.375rem;
                    font-weight: 300;
                }
            }
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    .info-card-3 {
        display: flex;
        justify-content: flex-start;
        position: relative;
        margin-left: -8rem;

        display: none;

        img {
            width: 19.375rem;
            height: 14.5rem;
            border-radius: 30px;
        }

        div {
            margin: 0 0 0 1rem;

            header {
                p {
                    font-size: 1.75rem;
                    color: #2e2e2e;

                    strong {
                        color: #fe8418;
                        font-weight: bold;
                    }

                    &:before {
                        content: "";
                        width: 24%;
                        height: 2px;
                        background: #fe8418;
                        position: absolute;
                        top: 14%;
                        right: -14%;
                    }

                    @media screen and (max-width: 768px) {
                        &:before {
                            content: none;
                        }
                    }
                }
            }

            main {
                p {
                    max-width: 420px;
                    font-size: 1.375rem;
                }
            }
        }

        @media (max-width: 768px) {
            text-align: right;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            text-align: center;
            margin-left: 0;
            p {
                max-width: 500px;
            }
        }

        @media (max-width: 414px) {
            margin-left: 0;
            display: block;
            p {
            }
        }
    }

    .differences {
        position: relative;

        margin-top: 2rem;
        margin-bottom: 5rem;

        .slick-slider {
            div {
                margin: 0 auto;

                @media (max-width: 768px) {
                    margin: 0 0.5rem;
                }

                @media (max-width: 414px) {
                    margin: 0 auto;
                }
            }

            @media (max-width: 768px) {
                max-width: 700px;
            }
        }

        > p {
            color: #000000;
            font-size: 2.375rem;
            text-align: right;
            margin: 0;

            strong {
                color: #fe8418;
                font-weight: bold;
            }

            &:before {
                content: "";
                width: 58%;
                height: 2px;
                background: #000;
                position: absolute;
                top: 12%;
                left: 0%;
            }
        }

        @media (max-width: 1024px) {
            > p {
                &:before {
                    width: 38%;
                    top: 17%;
                    left: 1%;
                }
            }
        }

        @media (max-width: 768px) {
            margin-bottom: 0;

            > p {
                font-size: 2rem;
                text-align: center;
                margin-bottom: 3rem;

                &:before {
                    content: "";
                    width: 60%;
                    height: 2px;
                    background: #000;
                    position: absolute;
                    top: 20%;
                    left: 20%;
                }
            }

            .slick-next {
                top: 25%;
                right: -3%;
            }

            .slick-prev {
                top: 25%;
                left: -3%;
            }
        }

        @media (max-width: 414px) {
            .slick-next {
                top: 13%;
                right: 0%;
            }

            .slick-prev {
                top: 13%;
                left: 5%;
            }
            .wrap-carousel {
                margin-top: 4rem;
            }

            > p {
                text-align: center;
                font-size: 1.4rem;

                &:before {
                    content: "";
                    width: 80%;
                    height: 2px;
                    background: #000;
                    position: absolute;
                    top: 10%;
                    left: 10%;
                }
            }
        }

        @media (max-width: 360px) {
            max-width: 350px;
        }
    }
`;

export const PieContainer = styled.div<{ isBrSpace?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .textUp {
        max-width: ${({ isBrSpace }) => (isBrSpace ? "100px" : "200px")};
    }

    img {
        margin: 1rem;
        width: 10rem;
        height: 10rem;
    }

    p {
        max-width: 200px;
        text-align: center;
        margin-bottom: 0;

        &:first-child {
            margin-top: 2rem;
        }
    }

    strong {
        font-size: 4rem;
        line-height: 4rem;
        text-align: center;
    }
`;

export const DifferencesCardContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    max-width: 460px;

    .image-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5.65rem;
        height: 5.65rem;
        margin: 0.5rem;
        padding: 0;

        border: 4px solid #fe8418;
        border-radius: 50%;
        img {
            width: 2.3rem;
        }
    }

    .differences-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p {
            color: #2e2e2e;
            max-width: 310px;
            font-size: 1.125rem;
            width: 100%;
            margin: 0;
        }

        strong {
            color: #fe8418;
            font-size: 1.375rem;
            font-weight: bold;
        }
    }

    @media (max-width: 1024px) {
        padding: 0.2rem;
    }

    @media (max-width: 768px) {
        max-width: 750px;
        padding: 0rem;

        .differences-content {
            margin: 0 0.5rem;
        }
    }

    @media (max-width: 414px) {
        max-width: 400px;
        flex-direction: column;

        text-align: center;

        .differences-content {
            text-align: center;

            strong {
                text-align: center;
            }

            p {
                max-width: 350px;
                padding: 0.5rem 0;
            }
        }
    }
`;
