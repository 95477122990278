import React from "react";
import { Container } from "react-bootstrap";
import { Content } from "./styles";
import Slider from "react-slick";
import Segments from "./Segments";
import { NextArrowCarusel, PrevArrowCarusel } from "~/shared/ArrowsCarousel";

const MarketOperate = () => {
    const settingSegments = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        rows: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        centerPadding: "60px",
        nextArrow: <NextArrowCarusel />,
        prevArrow: <PrevArrowCarusel />,

        responsive: [
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    rows: 1,
                },
            },

            {
                breakpoint: 414,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 1,
                },
            },
        ],
    };

    const settingProducts = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        autoplay: false,
        autoplaySpeed: 2000,
        centerPadding: "60px",
        nextArrow: <NextArrowCarusel />,
        prevArrow: <PrevArrowCarusel />,

        responsive: [
            {
                breakpoint: 414,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 1,
                },
            },
        ],
    };

    return (
        <Container>
            <Content>
                <div className="market-title">
                    <p>
                        {" "}
                        Segmentos do <strong>mercado que atuamos</strong>
                    </p>
                </div>

                <div className="segments">
                    <Slider {...settingSegments}>
                        <Segments image="/images/home/imobiliario.jpg" text="Imobiliário" link="/servicos-imobiliarios" />
                        <Segments image="/images/home/hotelaria.jpg" text="Hotelaria" />
                        <Segments image="/images/home/veiculos.jpg" text="Veículos" />
                        <Segments image="/images/home/gastronomia.jpg" text="Gastronomia" />
                        <Segments image="/images/home/ecomerce.jpg" text="E-commerce" />
                        <Segments image="/images/home/formatura.jpg" text="Formaturas" />
                        <Segments image="/images/home/casamento.jpg" text="Casamentos" />
                        <Segments image="/images/home/corporativo.jpg" text="Corporativo" />
                        <Segments image="/images/home/ensaios.jpg" text="Ensaios" />
                        <Segments image="/images/home/festas.jpg" text="Festas" />
                    </Slider>
                </div>

                <div className="product-offer">
                    <p>
                        {" "}
                        Produtos <strong>que oferecemos</strong>
                    </p>
                </div>

                <div className="product-wrap">
                    <Slider {...settingProducts}>
                        <div className="product">
                            <div>
                                <div>
                                    <img src="/images/home/camera-branco.png" alt="foto" />
                                </div>
                            </div>

                            <br />
                            <strong>FOTOS</strong>
                        </div>

                        <div className="product">
                            <div>
                                <div>
                                    <img src="/images/home/movie.png" alt="foto" />
                                </div>
                            </div>
                            <br />
                            <strong>VÍDEOS</strong>
                        </div>

                        <div className="product">
                            <div>
                                <div>
                                    <img src="/images/home/computer.png" alt="foto" />
                                </div>
                            </div>
                            <br />
                            <strong>TOUR VIRTUAL</strong>
                            <p>(2D / MATTERPORT)</p>
                        </div>
                    </Slider>
                    <br />
                    <br />
                    <a target="_blank" href="https://ajuda.fotop.com.br/kb/pt-br/form/1812">
                        Deixe a gente te ajudar
                    </a>
                </div>
            </Content>
        </Container>
    );
};

export default MarketOperate;
