import {ReactNode } from 'react'
import { CustomArrowProps } from 'react-slick'
import { PieContainer, DifferencesCardContainer} from './styles'

type CardPieProps = {
    image: string
    number:number
    textUp:string
    textDown:string
    isBrSpace?:boolean
}


export const NextArrowCarusel = (props: CustomArrowProps) => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          background: 'orange',
          zIndex: 10,
          borderRadius: '50%'
        }}
        onClick={onClick}
      >
        <img src="/images/arrow-right.png" />
      </div>
    )
  }

  export const PrevArrowCarusel = (props: CustomArrowProps) => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          background: 'orange',
          zIndex: 10,
          borderRadius: '50%'
        }}
        onClick={onClick}
      >
        <img src="/images/arrow-left.png" />
      </div>
    )
  }

  export const CardPie = ({ image, number, textUp, textDown, isBrSpace }: CardPieProps) => {

    return (
        <PieContainer isBrSpace={isBrSpace}>
                <img src={image} alt="gráfico de pizza" />
                    <p className="textUp"> {textUp} </p>
                    <strong> {number}% </strong>
                    <p>{textDown}</p>
        </PieContainer>
    )
}

export const DifferencesCard = ({image, title, children}) => {

    return (
        <DifferencesCardContainer>
            <div className="image-content">
                <img src={image} alt="imagem" />
            </div>
            <div className="differences-content">
                <p><strong>{title}</strong></p>
                <main>{children}</main>
            </div>
        </DifferencesCardContainer>
    )
}

